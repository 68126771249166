.admin-panel-users {
  &-page {
    padding-bottom: 50px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__header {
    padding: 15px 40px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__remove-btn {
    background: linear-gradient(180deg, #df5b5b 0%, #cd3d3d 100%);
    box-shadow: inset 0px -1px 0px rgba(139, 42, 42, 0.326455);
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    &:hover {
      background: linear-gradient(180deg, #df5b5b 100%, #cd3d3d 0%);
    }
    &:active {
      background: #a6a6a6;
    }
  }
}
