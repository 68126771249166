.ant-pagination,
.ant-pagination ol,
.ant-pagination ul {
  text-align: center;
  margin: 0 0 20px;
}

.ant-pagination-item:hover,
.ant-pagination-item:active,
.ant-pagination-item:focus,
.ant-pagination-item-active,
.ant-pagination-next:hover,
.ant-pagination-prev:hover,
.ant-pagination-next,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  border-color: #df955b;
  color: #df955b;
}
.ant-pagination-item-active a,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #df955b;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  svg {
    fill: #df955b;
  }
}
