@import './antd-default.scss';

* {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}
body {
  font-size: 14px;
  scroll-behavior: smooth;
}
.root-container {
  display: flex;
}

#root {
  display: flex;
}

/*Btn*/
.btn {
  background: linear-gradient(180deg, #df955b 0%, #cd743d 100%);
  box-shadow: inset 0px -1px 0px rgba(139, 42, 42, 0.326455);
  border-radius: 3px;
  border: none;
  padding: 13px 25px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  color: #ffffff;
}
.btn:hover {
  background: linear-gradient(180deg, #df955b 100%, #cd743d 0%);
  color: #fff;
}
.btn-outline {
  background: none;
  border: 1px solid #cd753d;
  box-sizing: border-box;
  border-radius: 3px;
  color: #1d1d1d;
}
.btn-outline:hover {
  background: #f2f2f2;
}
.btn.btn-small-outline {
  padding: 10px;
  font-size: 15px;
  font-weight: normal;
  background: none;
  border: 1px solid #cd753d;
  color: #cd753d;
  box-shadow: none;
  transform: background-color 0.4s ease-in;
  white-space: nowrap;
  &:hover {
    background-color: #cd753d;
    color: #fff;
  }
}

.btn-add-file {
  position: relative;
  &__description {
    margin-left: 14px;
    font-weight: 300;
    font-size: 13px;
    color: #747474;
  }
}
.btn-add-file svg {
  margin-right: 10px;
}
.btn-add-file input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.ant-table table {
  background-color: #fff;
}

.ant-table-tbody {
  color: #1d1d1d !important;
}

.ant-table-thead > tr > th {
  background: #fff !important;
  border-bottom: 2px solid #e8e8e8 !important;
}

.ant-table-thead > tr > th {
  font-size: 14px;
  line-height: 16px;
  color: #797979 !important;
  font-weight: normal !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 6px 20px;
}

.ant-alert-info {
  background: #f0f4f7 !important;
  border: 1px solid #3b6bc9 !important;
}

.ant-alert-info .ant-alert-icon {
  color: #3b6bc9 !important;
}

.ant-select-item-option-content {
  color: #1d1d1d !important;
}

@media all and (max-width: 560px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-range-wrapper {
    min-width: 0px !important;
  }
}
