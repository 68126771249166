.custom-select-container {
  margin-top: 20px;
  width: 100%;
  .custom-select {
    width: 100%;
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 10px;
    &__no-options {
      text-align: center;
      color: rgb(95, 95, 95);
      display: block;
      padding: 5px 0;
    }
  }
  .custom-select__menu-list {
    padding: 0;
  }
  .custom-select__value-container {
    font-size: 13px;
  }
  .custom-select__control {
    background-color: #efefef;
    color: #1d1d1d;
    border: none;
    box-shadow: inset 0px -1px 0px rgba(230, 230, 230, 0.5);
    font-weight: 400;
    padding: 0 3px;
  }
  .custom-select__control:hover {
    border-color: #b75c37;
    box-shadow: none;
  }
  .custom-select__control--is-focused {
    border-color: #b75c37;
    box-shadow: none;
    background-color: #efefef;
  }
  .custom-select__indicator-separator {
    display: none;
  }
  .custom-select__dropdown-indicator {
    color: #b75c37;
    font-size: 12px;
  }
  .custom-select__option--is-focused,
  .custom-select__option--is-selected {
    background-color: #efefef;
    color: #b75c37;
  }
  .custom-select__menu {
    margin-top: 0;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .custom-select__option:hover,
  .custom-select__option:active {
    background-color: #efefef;
    color: #b75c37;
  }
  .custom-select__option {
    color: #b75c37;
    border-bottom: 1px solid #ededed;
    background-color: #f5f5f5;
    font-size: 13px;
  }
  &__filter {
    display: inline-block;
    margin-right: 30px;
    font-weight: 500;
    font-size: 15px;
    color: #1e1e1e;
    input {
      margin-top: 7px;
      background-color: #efefef;
      border-radius: 3px;
      border: none;
      padding: 12px 15px;
      font-size: 15px;
    }
  }
}
