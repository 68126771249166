.global-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5em;
  opacity: 0;
  z-index: -1;
  &--active {
    z-index: 10;
    opacity: 1;
  }
}
