.finances-filters__select-container {
  max-width: 213px;
  display: inline-block;
  width: 213px;
  margin-right: 15px;
  label {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.02em;
    .custom-select-container {
      margin-top: 0;
    }
  }
  label > input {
    background-color: #efefef;
    border: none;
    box-shadow: inset 0 -1px 0 hsla(0, 0%, 90.2%, 0.5);
    color: #1d1d1d;
    font-weight: 400;
    font-size: 15px;
    padding: 13px 10px 11px;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media all and (max-width: 454px) {
  .finances-filters__select-container {
    width: 100%;
    max-width: 100%;
  }
}

.reports-filters__row {
  max-width: 213px;
  width: 213px;
  display: inline-block;
  margin-right: 10px;
}

.support-page {
  &__search {
    width: 100%;
    max-width: 700px;
    margin-top: 11px;
  }
  &__search-field {
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 3px;
    font-size: 14px;
    color: #8b8b8b;
    border: none;
    padding: 15px 20px;
    box-sizing: border-box;
  }
}

.finances-filters-select--pulse {
  &:before {
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    border: 1px solid transparent;
    border-radius: 4px;
    animation: pulse 1s infinite ease-out;
  }
}

@keyframes pulse {
  0% {
    border-color: #df955b;
  }
  25% {
    border-color: transparent;
  }
  50% {
    border-color: #df955b;
  }
  75% {
    border-color: transparent;
  }
  100% {
    border-color: #df955b;
  }
}
