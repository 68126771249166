.admin-panel-user {
  font-size: 14px;
  max-width: 340px;
  display: inline-block;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(236, 233, 233, 0.5);
  border-radius: 3px;
  padding: 10px;
  padding-right: 90px;
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  width: calc(33% - 110px);
  min-width: 300px;
  &__header {
    padding-left: 30px;
  }
  &__name {
    margin-bottom: 5px;
    display: inline-block;
    font-weight: 500;
    letter-spacing: 0.007em;
    color: rgba(0, 0, 0, 0.65);
  }
  &__group {
    font-size: 12px;
    color: #747474;
    margin-bottom: 10px;
    font-weight: 300;
  }
  &__email {
    a {
      font-weight: 300;
      color: #f2a040;
      text-decoration: none;
    }
  }
  &__status {
    color: #f24040;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.contracts-list__tag {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  background-color: #eee;
  padding: 1px 3px;
  border-radius: 3px;
}

@media all and (min-width: 1100px) {
  .admin-panel-user {
    min-width: calc(33.3% - 10px);
    width: calc(33.3% - 10px);
    max-width: 100%;
    vertical-align: top;
  }
}

@media all and (max-width: 1100px) {
  .admin-panel-user {
    min-width: calc(50% - 11px);
    width: calc(50% - 11px);
    max-width: 100%;
  }
}
@media all and (max-width: 600px) {
  .admin-panel-user {
    width: 100%;
    max-width: 100%;
  }
  .admin-panel-user__status {
    display: none;
  }
  .admin-panel-user__status-remove-btn {
    display: block;
  }
  .admin-panel-users__list .admin-panel-user {
    padding-right: 10px;
  }
  .admin-panel-user__name {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
