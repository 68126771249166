@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'), url('Robotolight.woff2') format('woff2'),
    url('Robotolight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'), url('Roboto.woff2') format('woff2'), url('Roboto.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'), url('Robotomedium.woff2') format('woff2'),
    url('Robotomedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'), url('Robotobold.woff2') format('woff2'),
    url('Robotobold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
