* {
  outline-color: #cd743d;
  outline: none;
}

.main-wrapper {
  display: flex;
  min-height: 100%;
  a {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: inherit;
    }
  }
}

.side-menu {
  height: 100%;
  overflow-y: auto;
  width: 277px;
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

.ball-spin-fade-loader > div {
  background-color: #cd743d;
}
.loader-container {
  align-items: center;
  display: flex;
  height: 50%;
  justify-content: center;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.main-wrapper .scrollarea .scrollbar-container {
  z-index: 0;
}

@media all and (max-width: 480px) {
  .main-wrapper {
    overflow: hidden;
  }
}

@media all and (max-width: 375px) {
  #root {
    overflow: hidden;
  }
}

.document__status.rejected {
  border-color: grey;
  color: grey;
}
